li.repo {
  display: flex;
  gap: 20px;
  padding: 20px 0 20px 30px;
  font-family: 'Roboto', sans-serif;
}

li.repo:nth-child(odd) {
  background-color: rgb(239, 239, 239);
}

li.repo img {
  width: 120px;
  border-radius: 2px;
}

li.repo .repo-name {
  margin-top: 0;
  font-weight: 500;
}

li.repo .stars,
li.repo .issues {
  border: 1px solid;
  margin-right: 10px;
  padding: 3px 7px;
  border-radius: 2px;
}

@media screen and (max-width: 576px) {
  li.repo {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 2px;
  }

  li.repo .stars,
  li.repo .issues {
    display: block;
    width: 120px;
    margin: 10px auto;
  }
}
