@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);
li.repo {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px 0 20px 30px;
  font-family: 'Roboto', sans-serif;
}

li.repo:nth-child(odd) {
  background-color: rgb(239, 239, 239);
}

li.repo img {
  width: 120px;
  border-radius: 2px;
}

li.repo .repo-name {
  margin-top: 0;
  font-weight: 500;
}

li.repo .stars,
li.repo .issues {
  border: 1px solid;
  margin-right: 10px;
  padding: 3px 7px;
  border-radius: 2px;
}

@media screen and (max-width: 576px) {
  li.repo {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 2px;
  }

  li.repo .stars,
  li.repo .issues {
    display: block;
    width: 120px;
    margin: 10px auto;
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 85%;
  margin: 10px auto;
}

.pagination button {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 500;
}

.pagination button.active {
  box-shadow: 0 0 3px 0 #601c1c;
}

body,
:root {
  margin: 0;
  overflow-x: hidden;
}

ul.app {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

ul.app::after {
  content: '';
  display: block;
  margin: 20px auto;
  border: 2px solid rgb(239, 239, 239);
  border-radius: 2px;
  width: 50%;
}

/* Loading  */
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(239, 239, 239);
  color: rgb(32, 32, 51);
  transform: translateZ(1px);
}

.loading h2 {
  font-size: 30px;
}

.loading > span {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: rgb(32, 32, 51);
  animation: loading 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes loading {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

/* No Repos */
h1.no-repos {
  text-align: center;
  color: rgb(32, 32, 51);
  font-size: 50px;
  font-family: 'Roboto', sans-serif;
}

